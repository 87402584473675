<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-btn icon>
        <v-icon>mdi-clock-edit</v-icon>
      </v-btn>

      <v-toolbar-title>Sonderstundenplan</v-toolbar-title>

      <v-spacer />

      <SchoolClassInput label="Klasse" hideDetails v-model="schoolClass" />
    </v-app-bar>

    <router-view :schoolClass="schoolClass"></router-view>
  </v-container>
</template>

<script>
import SchoolClassInput from "@/components/SchoolClassInput.vue";
export default {
  name: "LessonChanges",
  components: { SchoolClassInput },
  data: () => ({
    schoolClass: {},
  }),
  watch: {
    "schoolClass.id": function (newVal) {
      if (!newVal || this.$route.params.id == newVal) {
        return;
      }
      this.$router.push({
        name: "LessonChangesSchoolclass",
        params: { id: newVal },
      });

      localStorage.setItem(
        "lessonChangesSchoolClass",
        JSON.stringify(this.schoolClass)
      );
    },
  },
  async created() {
    if (this.$route.params.id) {
      this.schoolClass = await this.apiGet({
        resource: "schoolclass/schoolclass",
        id: this.$route.params.id,
      });
    } else {
      if (localStorage.getItem("lessonChangesSchoolClass")) {
        this.schoolClass = JSON.parse(
          localStorage.getItem("lessonChangesSchoolClass")
        );
      }
    }
  },
};
</script>
